import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-24">
      <h1 className="text-3xl font-bold mb-8">{t('privacy.title')}</h1>

      <section className="mb-8" id="gdpr">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.gdpr.title')}</h2>
        <p className="mb-4">{t('privacy.gdpr.intro')}</p>
        <ul className="list-disc pl-6 mt-2">
          <li>{t('privacy.gdpr.rights.access')}</li>
          <li>{t('privacy.gdpr.rights.rectification')}</li>
          <li>{t('privacy.gdpr.rights.erasure')}</li>
          <li>{t('privacy.gdpr.rights.restrict')}</li>
          <li>{t('privacy.gdpr.rights.portability')}</li>
          <li>{t('privacy.gdpr.rights.object')}</li>
        </ul>
        <p className="mt-4">{t('privacy.gdpr.contact')}</p>
      </section>

      <section className="mb-8" id="cookies">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.cookies.title')}</h2>
        <p className="mb-4">{t('privacy.cookies.intro')}</p>
        <h3 className="text-lg font-semibold mb-2">{t('privacy.cookies.types.title')}</h3>
        <ul className="list-disc pl-6 mt-2">
          <li>
            <span className="font-medium">{t('privacy.cookies.types.essential.title')}</span>: 
            {t('privacy.cookies.types.essential.description')}
          </li>
          <li>
            <span className="font-medium">{t('privacy.cookies.types.analytics.title')}</span>: 
            {t('privacy.cookies.types.analytics.description')}
          </li>
          <li>
            <span className="font-medium">{t('privacy.cookies.types.preferences.title')}</span>: 
            {t('privacy.cookies.types.preferences.description')}
          </li>
        </ul>
        <p className="mt-4">{t('privacy.cookies.management')}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section1.title')}</h2>
        <p className="mb-4">
          {t('privacy.section1.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section1.items.google')}</li>
            <li>{t('privacy.section1.items.recipe')}</li>
            <li>{t('privacy.section1.items.usage')}</li>
            <li>{t('privacy.section1.items.device')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section2.title')}</h2>
        <p className="mb-4">
          {t('privacy.section2.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section2.items.service')}</li>
            <li>{t('privacy.section2.items.experience')}</li>
            <li>{t('privacy.section2.items.communication')}</li>
            <li>{t('privacy.section2.items.security')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section3.title')}</h2>
        <p className="mb-4">
          {t('privacy.section3.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section3.items.storage')}</li>
            <li>{t('privacy.section3.items.encryption')}</li>
            <li>{t('privacy.section3.items.audits')}</li>
            <li>{t('privacy.section3.items.selling')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section4.title')}</h2>
        <p className="mb-4">
          {t('privacy.section4.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section4.items.google')}</li>
            <li>{t('privacy.section4.items.openai')}</li>
            <li>{t('privacy.section4.items.webscraping')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section5.title')}</h2>
        <p className="mb-4">
          {t('privacy.section5.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section5.items.access')}</li>
            <li>{t('privacy.section5.items.correction')}</li>
            <li>{t('privacy.section5.items.export')}</li>
            <li>{t('privacy.section5.items.optout')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section6.title')}</h2>
        <p className="mb-4">
          {t('privacy.section6.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section6.items.respect')}</li>
            <li>{t('privacy.section6.items.notsell')}</li>
            <li>{t('privacy.section6.items.personaluse')}</li>
            <li>{t('privacy.section6.items.attribution')}</li>
            <li>{t('privacy.section6.items.remove')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('privacy.section7.title')}</h2>
        <p className="mb-4">
          {t('privacy.section7.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('privacy.section7.items.contact')}</li>
            <li>{t('privacy.section7.items.verification')}</li>
            <li>{t('privacy.section7.items.specify')}</li>
            <li>{t('privacy.section7.items.process')}</li>
          </ul>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { 
  FaFacebook as FacebookIcon,
  FaTwitter as TwitterIcon,
  FaLinkedin as LinkedinIcon 
} from 'react-icons/fa';

const Hero = () => {
  const { t } = useTranslation();

  const shareUrl = 'https://bunataturi.ro';
  const title = t('hero.title.part1') + ' ' + t('hero.title.part2');

  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background image with gradient overlay */}
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1499028344343-cd173ffc68a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
          alt="Delicious food"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/70 to-black"></div>
      </div>

      {/* Content */}
      <div className="relative w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-block px-4 py-1 rounded-full bg-primary-900/50 backdrop-blur-sm mb-8">
              <p className="text-sm text-primary-400">{t('hero.status')}</p>
            </div>
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block">{t('hero.title.part1')}</span>{' '}
              <span className="block text-primary-400">{t('hero.title.part2')}</span>
            </h1>
            <p className="mt-6 text-xl text-gray-300 max-w-2xl mx-auto">
              {t('hero.description')}
            </p>
            <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center">
              <a
                href="#register"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:text-lg"
              >
                {t('hero.buttons.getStarted')}
              </a>
              <a
                href="#features"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-100 bg-primary-900 bg-opacity-40 hover:bg-opacity-50 md:text-lg"
              >
                {t('hero.buttons.viewRoadmap')}
              </a>
            </div>

            {/* Social Share Buttons */}
            <div className="mt-8 flex justify-center space-x-4">
              <FacebookShareButton url={shareUrl} quote={title}>
                <div className="p-2 rounded-full border border-gray-600 hover:border-gray-500 transition-colors" 
                     title={t('share.facebook')}>
                  <FacebookIcon className="w-5 h-5 text-white" />
                </div>
              </FacebookShareButton>
              
              <TwitterShareButton url={shareUrl} title={title}>
                <div className="p-2 rounded-full border border-gray-600 hover:border-gray-500 transition-colors"
                     title={t('share.twitter')}>
                  <TwitterIcon className="w-5 h-5 text-white" />
                </div>
              </TwitterShareButton>
              
              <LinkedinShareButton url={shareUrl} title={title}>
                <div className="p-2 rounded-full border border-gray-600 hover:border-gray-500 transition-colors"
                     title={t('share.linkedin')}>
                  <LinkedinIcon className="w-5 h-5 text-white" />
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero; 
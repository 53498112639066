import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto px-4 py-24">
      <h1 className="text-3xl font-bold mb-8">{t('terms.title')}</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section1.title')}</h2>
        <p className="mb-4">
          {t('terms.section1.content')}
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section2.title')}</h2>
        <p className="mb-4">
          {t('terms.section2.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section2.items.save')}</li>
            <li>{t('terms.section2.items.create')}</li>
            <li>{t('terms.section2.items.share')}</li>
            <li>{t('terms.section2.items.convert')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section3.title')}</h2>
        <p className="mb-4">
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section3.items.register')}</li>
            <li>{t('terms.section3.items.responsibility')}</li>
            <li>{t('terms.section3.items.accuracy')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section4.title')}</h2>
        <p className="mb-4">
          {t('terms.section4.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section4.items.inappropriate')}</li>
            <li>{t('terms.section4.items.violation')}</li>
            <li>{t('terms.section4.items.illegal')}</li>
            <li>{t('terms.section4.items.disrupt')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section5.title')}</h2>
        <p className="mb-4">
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section5.items.rights')}</li>
            <li>{t('terms.section5.items.attribution')}</li>
            <li>{t('terms.section5.items.user')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section6.title')}</h2>
        <p className="mb-4">
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section6.items.responsibility')}</li>
            <li>{t('terms.section6.items.ownership')}</li>
            <li>{t('terms.section6.items.selling')}</li>
            <li>{t('terms.section6.items.purpose')}</li>
            <li>{t('terms.section6.items.removal')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.section7.title')}</h2>
        <p className="mb-4">
          {t('terms.section7.intro')}
          <ul className="list-disc pl-6 mt-2">
            <li>{t('terms.section7.items.organize')}</li>
            <li>{t('terms.section7.items.convert')}</li>
            <li>{t('terms.section7.items.notes')}</li>
            <li>{t('terms.section7.items.management')}</li>
          </ul>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{t('terms.gdpr.title')}</h2>
        <p className="mb-4">{t('terms.gdpr.description')}</p>
        <ul className="list-disc pl-6 mt-2">
          <li>{t('terms.gdpr.compliance.consent')}</li>
          <li>{t('terms.gdpr.compliance.transparency')}</li>
          <li>{t('terms.gdpr.compliance.rights')}</li>
          <li>{t('terms.gdpr.compliance.data')}</li>
        </ul>
      </section>
    </div>
  );
};

export default TermsAndConditions;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '@heroicons/react/24/outline';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-primary-500">404</h1>
        <h2 className="mt-4 text-2xl font-semibold text-white">
          {t('notFound.title')}
        </h2>
        <p className="mt-2 text-gray-400">
          {t('notFound.description')}
        </p>
        <Link
          to="/"
          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
        >
          <HomeIcon className="w-5 h-5 mr-2" />
          {t('notFound.backHome')}
        </Link>
      </div>
    </div>
  );
};

export default NotFound; 
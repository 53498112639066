import React from 'react';
import { 
  HomeIcon, 
  HeartIcon, 
  UserGroupIcon, 
  SparklesIcon 
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const Vision = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-black py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center space-y-12">
          <div>
            <h2 className="text-3xl font-bold text-white sm:text-4xl">
              {t('vision.title')}
            </h2>
            <p className="mt-4 text-lg text-gray-400">
              {t('vision.subtitle')}
            </p>
          </div>

          <div className="grid gap-8 md:grid-cols-3">
            <div className="space-y-4">
              <div className="flex justify-center">
                <HomeIcon className="w-8 h-8 text-primary-400" />
              </div>
              <h3 className="text-xl font-semibold text-primary-400">
                {t('vision.sections.homeCooks.title')}
              </h3>
              <p className="text-gray-400">
                {t('vision.sections.homeCooks.description')}
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex justify-center">
                <HeartIcon className="w-8 h-8 text-primary-400" />
              </div>
              <h3 className="text-xl font-semibold text-primary-400">
                {t('vision.sections.foodLovers.title')}
              </h3>
              <p className="text-gray-400">
                {t('vision.sections.foodLovers.description')}
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex justify-center">
                <UserGroupIcon className="w-8 h-8 text-primary-400" />
              </div>
              <h3 className="text-xl font-semibold text-primary-400">
                {t('vision.sections.communities.title')}
              </h3>
              <p className="text-gray-400">
                {t('vision.sections.communities.description')}
              </p>
            </div>
          </div>

          <div className="max-w-2xl mx-auto">
            <p className="text-lg text-gray-300 italic">
              {t('vision.quote')}
            </p>
          </div>

          <div className="bg-primary-900/20 backdrop-blur-sm rounded-xl p-8 border border-primary-900/50">
            <div className="flex justify-center mb-4">
              <SparklesIcon className="w-8 h-8 text-primary-400" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-4">
              {t('vision.promise.title')}
            </h3>
            <p className="text-gray-300">
              {t('vision.promise.description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision; 
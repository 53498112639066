import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BeakerIcon,
  SpeakerWaveIcon,
  ShoppingCartIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

const Features = () => {
  const { t } = useTranslation();

  const timelineItems = [
    {
      phase: "Phase 1",
      status: "live",
      icon: BeakerIcon,
      title: t('features.phases.phase1.title'),
      date: t('features.months.january'),
      items: t('features.phases.phase1.items', { returnObjects: true })
    },
    {
      phase: "Phase 2",
      status: "upcoming",
      icon: SpeakerWaveIcon,
      title: t('features.phases.phase2.title'),
      date: t('features.months.february'),
      items: t('features.phases.phase2.items', { returnObjects: true })
    },
    {
      phase: "Phase 3",
      status: "upcoming",
      icon: ShoppingCartIcon,
      title: t('features.phases.phase3.title'),
      date: t('features.months.march'),
      items: t('features.phases.phase3.items', { returnObjects: true })
    },
    {
      phase: "Phase 4",
      status: "upcoming",
      icon: StarIcon,
      title: t('features.phases.phase4.title'),
      date: t('features.months.april'),
      items: t('features.phases.phase4.items', { returnObjects: true })
    }
  ];

  return (
    <div id="features" className="bg-black py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white">{t('features.title')}</h2>
          <p className="mt-4 text-gray-400">{t('features.subtitle')}</p>
        </div>

        <div className="relative">
          {/* Continuous timeline line */}
          <div className="hidden md:block absolute top-[29px] left-0 right-0 h-0.5 bg-primary-900">
            <div className="absolute inset-0 bg-primary-500" style={{ width: '25%' }}></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {timelineItems.map((item, index) => (
              <div key={item.phase} className="relative">
                {/* Date and dot */}
                <div className="hidden md:block absolute -top-10 w-full">
                  {/* Date */}
                  <div className="text-center mb-4">
                    <span className="text-primary-400 text-sm">
                      {item.date}
                    </span>
                  </div>
                  
                  {/* Timeline dot */}
                  <div className="absolute left-1/2 transform -translate-x-1/2">
                    <div className={`w-3 h-3 rounded-full ${
                      item.status === 'live'
                        ? 'bg-primary-400'
                        : 'bg-primary-900'
                    }`}></div>
                  </div>
                </div>

                {/* Card */}
                <div className={`relative mt-12 p-6 rounded-xl border ${
                  item.status === 'live' 
                    ? 'bg-primary-900/20 border-primary-800' 
                    : 'bg-gray-900/20 border-gray-800'
                } hover:scale-105 transition-transform duration-300 ease-in-out`}>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-primary-900/50">
                    <item.icon className="w-6 h-6 text-primary-400" />
                  </div>
                  
                  <h3 className="mt-4 text-xl font-semibold text-white text-center">{item.title}</h3>
                  
                  <ul className="mt-4 space-y-3">
                    {item.items.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center text-gray-300">
                        <span className="w-1.5 h-1.5 rounded-full bg-primary-400 mr-2"></span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
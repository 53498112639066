import React from 'react';
import { BookOpenIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ro', name: 'Română' }
  ];

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <header className="absolute w-full z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center space-x-2">
            <BookOpenIcon onClick={() => navigate('/')} className="w-8 h-8 text-primary-400" />
            <span onClick={() => navigate('/')} className="text-2xl font-bold text-primary-400">Bunătățuri</span>
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative group">
              <button
                className="flex items-center space-x-1 text-gray-300 hover:text-primary-400 transition-colors duration-200"
              >
                <GlobeAltIcon className="w-5 h-5" />
                <span className="text-sm">{languages.find(lang => lang.code === i18n.language)?.name || 'English'}</span>
              </button>
              
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-in-out">
                <div className="py-1 bg-gray-900" role="menu" aria-orientation="vertical">
                  {languages.map((language) => (
                    <button
                      key={language.code}
                      onClick={() => changeLanguage(language.code)}
                      className={`block w-full text-left px-4 py-2 text-sm ${
                        i18n.language === language.code
                          ? 'text-white bg-gray-800'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800'
                      }`}
                      role="menuitem"
                    >
                      {language.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header; 
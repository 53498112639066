import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black text-gray-300 z-50 shadow-lg border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="text-sm text-gray-400">
            <p>
              {t('cookies.message')}{' '}
              <Link 
                to="/privacy#cookies" 
                className="text-primary-400 hover:text-primary-300 underline transition-colors"
              >
                {t('cookies.learnMore')}
              </Link>
            </p>
          </div>
          <div className="flex gap-4">
            <button
              onClick={handleReject}
              className="px-4 py-2 text-sm font-medium rounded-md border border-gray-600 hover:border-gray-500 text-gray-300 hover:text-white transition-colors"
            >
              {t('cookies.reject')}
            </button>
            <button
              onClick={handleAccept}
              className="px-4 py-2 text-sm font-medium rounded-md bg-primary-600 hover:bg-primary-700 text-white transition-colors"
            >
              {t('cookies.accept')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 
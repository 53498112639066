import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import RegisterForm from './components/RegisterForm';
import Vision from './components/Vision';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import NotFound from './pages/NotFound';

function App() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Bunătățuri",
    "applicationCategory": "FoodAndDrink",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "RON"
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <link rel="canonical" href="https://bunataturi.ro" />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
      </Helmet>

      <Header />
      <Routes>
        <Route path="/" element={
          <div>
            <Hero />
            <div className="space-y-24">
              <div className="border-b border-gray-800">
                <RegisterForm />
              </div>
              <div className="border-b border-gray-800">
                <Vision />
              </div>
              <Features />
            </div>
          </div>
        } />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <CookieConsent />
      <ToastContainer 
        position="bottom-right"
        theme="dark"
      />
    </div>
  );
}

export default App; 